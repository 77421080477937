import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

import { validateCampaigns } from '../../helpers/GlobalFunctions.js';
import { getListAllImports } from '../table/TableActions';
import {setTableFilter} from '../table/filters/FilterActionss';

import Modal from '../modal/Modal';

class UploadAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      nameFile: 'Selecione um arquivo CSV',
      modal: false,
      titulo: '',
      subtitulo: '',
      codeError: '',
      tipo: '',
      parsedCsvFile: {},
      token: '',
      validateCaptcha: false,
      isLoading: false,

      partners: [],
      partner: {},
      partner_name: '',
      partner_code: '',
      campaign_name: '',
      campaign_code: '',
      partner_campaigns: [],
      validation_errors: [],

      loading_message: 'Carregando parceiros...',
      no_campaigns_message: false,
      disabled_select: true,
      disabled_select_campaign: true,
      disabled_upload: true,
      reCaptchaEnabled: false,
    };

    this.recaptchaRef = React.createRef();

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onHiden = this.onHiden.bind(this);
    this.getAsText = this.getAsText.bind(this);
    this.fileReadingFinished = this.fileReadingFinished.bind(this);
    this.processData = this.processData.bind(this);
    this.checkProperties = this.checkProperties.bind(this);
  }

  componentDidMount() {
    const BASE_URL = `${process.env.REACT_APP_URL}`;

    axios
      .get(`${BASE_URL}/partner/active`)
      .then((response) => {
        const partnersData = response.data.results;

        if (partnersData) {
          partnersData.sort(function (a, b) {
            if (a.partnerName < b.partnerName) {
              return -1;
            }
            if (a.partnerName > b.partnerName) {
              return 1;
            }
            return 0;
          });

          this.setState({
            partners: partnersData,
            disabled_select: false,
            loading_message: false,
          });
        } else {
          this.setState({
            partners: [],
            disabled_select: false,
            loading_message: false,
          });
        }
      })
      .catch((error) => {
        if (error.response && JSON.stringify(error.response.status) === '401') {
          this.props.logout();
        }
      });
  }

  handleRecaptchaSuccess = (token) => {
    this.setState({
      token: token,
      validateCaptcha: true,
    })
  }

  handleRecaptchaResets = () => {
    this.setState({
      token: '',
      validateCaptcha: false,
    })
  }

  validationCampaignTerm = (term) => {
    if (String(term).length === 10) {
      return true;
    }
    return false;
  }

  validationCustomerId = (id) => {
    var number = String(id).split('+');
    if (String(id).length === 0 || String(id).length > 14 || id === undefined || parseInt(number[1]) > 13 ) {
      return false;
    }
    return true;
  }

  validationEMail = (email) => {
    var result = String(email).includes('@');
    return result;
  }

  validationPhoneCountryCode = (countryCode) => {
    if (String(countryCode).length === 2) {
      return true;
    }
    return false;
  }

  validationPhoneAreaCode = (areaCode) => {
    if (String(areaCode).length === 2) {
      return true;
    }
    return false;
  }

  validationPhoneNumber = (number) => {
    if (String(number).length === 9) {
      return true;
    }
    return false;
  }

  validationIdProduct = (id) => {
    if (String(id) === '') {
      return false;
    }
    return true;
  }


  validationPurchaseDate = (date) => {
    if (String(date).length === 10) {
      return true;
    }
    return false;
  }

  validationTotalPointsAndTransactionAmount = (points, transaction) => {
    if(String(points) === '' && String(transaction) === '') {
      return false;
    }
    if(String(points) === '' && String(transaction) !== '' || String(transaction) === '' && String(points) !== '' || String(transaction) !== '' && String(points) !== '') {
      return true;
    }
  }

  validation = (campaign) => {

    var array_errors = [];
    campaign = Array.from(campaign);

    campaign.map((campaign, index) => {
      if(campaign['Campaign Term'] !== '') {
        if(!this.validationCampaignTerm(campaign['Campaign Term'])){
          array_errors.push({'column': 'Campaign Term', 'row': (index+2)});
        }
      }
      
      if(!this.validationCustomerId(campaign['Customer_ID'])){
        array_errors.push({'column': 'Customer_ID', 'row': (index+2)});
      }
      
      if(campaign['E-mail'] !== '') {
        if(!this.validationEMail(campaign['E-mail'])){
          array_errors.push({'column': 'E-mail', 'row': (index+2)});
        }
      }
      
      if(campaign['MOBILE_PHONE_AREA_CODE'] !== '') {
        if(!this.validationPhoneCountryCode(campaign['MOBILE_PHONE_AREA_CODE'])){
          array_errors.push({'column': 'MOBILE_PHONE_AREA_CODE', 'row': (index+2)});
        }
      }
      
      if(campaign['MOBILE_PHONE_COUNTRY_CODE'] !== '') {
        if(!this.validationPhoneAreaCode(campaign['MOBILE_PHONE_COUNTRY_CODE'])){
          array_errors.push({'column': 'MOBILE_PHONE_COUNTRY_CODE', 'row': (index+2)});
        }
      }
      
      if(campaign['MOBILE_PHONE_NUMBER'] !== '') {
        if(!this.validationPhoneNumber(campaign['MOBILE_PHONE_NUMBER'])){
          array_errors.push({'column': 'MOBILE_PHONE_NUMBER', 'row': (index+2)});
        }
      }

      if(!this.validationPurchaseDate(campaign['Purchase Data'])){
        array_errors.push({'column': 'Purchase Data', 'row': (index+2)});
      }

      if(!this.validationTotalPointsAndTransactionAmount(campaign['Total of points'], campaign['Transaction Amount'])){
        array_errors.push({'column': 'Total of points', 'row': (index+2)});
        array_errors.push({'column': 'Tansaction Amount', 'row': (index+2)});
      }
      
    });

    return array_errors;
  }

  onFormSubmit(e) {
    if (this.state.file != null) {
      e.preventDefault();
      this.state.isLoading = true;

      var object_campaigns = this.state.parsedCsvFile;

      object_campaigns = object_campaigns.filter((campaign) => {
        return this.checkProperties(campaign);
      });

      object_campaigns = validateCampaigns(object_campaigns, this.state.campaign_code, this.state.campaign_name);
      
      this.setState({validation_errors: object_campaigns});

      var validation = this.validation(object_campaigns);
      validation = Array.from(validation);

      if(validation.length === 0) {
        var rows = [Object.keys(object_campaigns[0] || {})];

        object_campaigns.forEach((campaign_object) => {
          const values = Object.values(campaign_object);
          rows.push(values);
        });
        
        let csvContent = rows.map((e) => e.join(';')).join('\n');

        let csvFile = new Blob([csvContent], {
          encoding: 'UTF-8',
          type: 'text/plain;charset=UTF-8',
        });

        this.fileUpload(csvFile, this.state.token)
          .then((resp) => {
            this.recaptchaRef.current.reset();
            this.handleRecaptchaResets();
            this.onClear();
            this.setState({ modal: true, tipo: 'sucesso' });
            this.props.getListAllImports();
            this.props.setTableFilter("normal");
            document.getElementById("user-filter-checkbox").checked = false;
            document.getElementById("date-filter-checkbox").checked = false;
          })
          .catch((error) => {
            this.recaptchaRef.current.reset();
            this.handleRecaptchaResets();
            this.onClear();
            if (!error.response.data.results) {
              this.setState({
                modal: true,
                titulo:
                  'Sua sessão expirou. Recarregue a página e tente novamente.',
                codeError: null,
                tipo: 'error',
              });
            } else if (typeof error.response.data.results.userMessage === 'undefined') {
              this.setState({
                modal: true,
                titulo:
                  'Erro inesperado entre em contato com nossos canais de atendimento',
                codeError: error.response.data.results.code,
                tipo: 'error',
              });
            } else {
              this.setState({
                modal: true,
                titulo: error.response.data.results.userMessage,
                codeError: error.response.data.results.code,
                tipo: 'error',
              });
            }
          }).finally(() => {
            this.state.isLoading = false;
          });
        } else {
          this.onClear();
          this.setState({
            modal: true,
            tipo: 'verificacao',
          });
          this.state.isLoading = false;
        }
    } else {
      this.onClear();
      this.setState({
        modal: true,
        titulo: 'Selecione um arquivo csv para o envio!',
        subtitulo: 'Favor baixar o arquivo csv de modelo.',
        tipo: 'alerta',
      });
    }
  }

  checkProperties(obj) {
    const columns_number = Object.keys(obj).length;
    var count_empty_values = 0;

    for (var key in obj) {
      if (obj[key] === null || obj[key] === '') {
        count_empty_values++;
      }
    }

    return !(columns_number === count_empty_values);
  }

  fileUpload(file, token) {
    const BASE_URL = `${process.env.REACT_APP_URL}`;
    const formData = new FormData();

    formData.append('file', file, this.state.nameFile);
    formData.append('partnerCode', this.state.partner_code);
    formData.append('token', token);

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    return axios.post(`${BASE_URL}/file/admin/${this.state.campaign_code}`, formData, config);
  }

  getFileExtension(filename) {
    return filename.split('.').pop();
  }

  getAsText(rawFile) {
    const reader = new FileReader();
    reader.readAsText(rawFile);
    reader.onload = this.fileReadingFinished;
  }

  fileReadingFinished(e) {
    var csv = e.target.result;
    this.processData(csv);
  }

  processData(csv) {
    const allTextLines = csv.split(/\r\n|\n/);
    const col_names = allTextLines[0].split(';');
    const lines_number = allTextLines.length;

    var campaigns = [];

    var line_index;

    for (line_index = 1; line_index < lines_number; line_index++) {
      const campaign = {};
      const line_data = allTextLines[line_index].split(';');

      col_names.forEach((column_name, column_index) => {
        campaign[column_name] = line_data[column_index];
      });

      campaigns.push(campaign);
    }

    this.setState({
      parsedCsvFile: campaigns,
    });
  }

  onChange(e) {
    if (typeof e.target.files[0] != 'undefined') {
      this.setState({ reCaptchaEnabled: true });
      const retorno = this.getFileExtension(e.target.files[0].name);
      if (retorno === 'csv') {
        this.setState({
          file: e.target.files[0],
          nameFile: e.target.files[0].name,
        });
        this.getAsText(e.target.files[0]);
      } else {
        this.onClear();
        this.setState({
          modal: true,
          titulo: 'Permitido apenas arquivo CSV!',
          subtitulo: 'Favor baixar o arquivo modelo.',
          tipo: 'alerta',
        });
      }
    }
  }

  onClear() {
    this.setState({ file: null, parsedCsvFile: {}, nameFile: 'Selecione um arquivo CSV' });
    document.getElementById('customFileLang').value = '';
  }

  onHiden() {
    this.setState({
      modal: false,
      titulo: '',
      subtitulo: '',
      codeError: '',
      tipo: '',
    });
  }

  handleChangeSelectPartner = (event) => {
    event.preventDefault();
    if (event.target.value === 'select') {
      this.setState({
        partner_code: '',
        partner_name: '',
        campaign_name: '',
        no_campaigns_message: false,
        disabled_select_campaign: true,
        disabled_upload: true,
      });
    } else {
      const partner_code_selected = event.target.value;
      const partner_array = this.state.partners.filter((partner) => {
        return partner.partnerCode === partner_code_selected;
      });
      const partner = partner_array[0];
      this.setState({
        partner: partner,
        partner_code: partner_code_selected,
        partner_name: partner.partnerName,
      });
      if (partner.campaigns) {
        partner.campaigns.sort(function (a, b) {
          if (a.campaignName < b.campaignName) {
            return -1;
          }
          if (a.campaignName > b.campaignName) {
            return 1;
          }
          return 0;
        });

        this.setState({
          partner_campaigns: partner.campaigns,
          disabled_select_campaign: false,
          no_campaigns_message: false,
          campaign_name: '',
        });
      } else {
        this.setState({
          no_campaigns_message: true,
          disabled_select_campaign: true,
          disabled_upload: true,
        });
      }
    }
  };

  handleChangeSelectCampaign = (event) => {
    event.preventDefault();
    if (event.target.value === 'select') {
      this.setState({
        campaign_name: '',
        campaign_code: '',
        disabled_upload: true,
      });
    } else {
      const campaign_name_selected = event.target.value;
      const campaign_found = this.state.partner_campaigns.filter((campaign) => {
        return campaign.campaignName === campaign_name_selected;
      });
      this.setState({
        campaign_name: campaign_name_selected,
        campaign_code: campaign_found[0].campaignCode,
        disabled_upload: false,
      });
    }
  };

  render() {
    const { reCaptchaEnabled } = this.state;
    return (
      <div className='col-md-6 mb-4' id='upload-box'>
        {this.state.modal ? (
          <Modal
            modal={this.state.modal}
            metodoHiden={this.onHiden}
            titulo={this.state.titulo}
            codeError={this.state.codeError}
            tipo={this.state.tipo}
            subtitulo={this.state.subtitulo}
            validationErrors={this.validation(this.state.validation_errors)}
          />
        ) : null}

        <div className='card mb-4 download-and-upload' id='upload-box'>
          <div className='card-header text-center'>
            Upload (Arquivos de pontos em CSV)
          </div>

          <div className='upload-wrapper'>
            {this.state.disabled_select === false ? (
              <div className='input-block' id='select-container'>
                <div className='select-box' id='select-box-partner'>
                  <label>Parceiro</label>
                  <select
                    onChange={this.handleChangeSelectPartner}
                    value={this.state.partner_code}
                  >
                    <option value='select'>Selecione</option>
                    {this.state.partners.map((partner) => (
                      <option
                        key={partner.partnerCode}
                        value={partner.partnerCode}
                      >
                        {partner.partnerName}
                      </option>
                    ))}
                  </select>
                </div>

                {this.state.no_campaigns_message ? (
                  <div className='no-campaigns-msg'>
                    Não há campanhas ativas para este parceiro.
                  </div>
                ) : (
                  <div className='select-box' id='select-box-campaign'>
                    {this.state.disabled_select_campaign ? (
                      <label className='label-disabled'>Campanha</label>
                    ) : (
                      <label>Campanha</label>
                    )}
                    <select
                      onChange={this.handleChangeSelectCampaign}
                      value={this.state.campaign_name}
                      disabled={this.state.disabled_select_campaign}
                    >
                      <option value='select'>Selecione</option>
                      {this.state.partner_campaigns.map((campaign) => (
                        <option
                          key={campaign.campaignCode}
                          value={campaign.campaignName}
                        >
                          {campaign.campaignName}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            ) : (
              <div className='loading-msg'>{this.state.loading_message}</div>
            )}

            <div className='upload-area'>
              <div className='input-area'>
                <div className='custom-file col-md-7'>
                  <input
                    type='file'
                    onChange={this.onChange}
                    className='custom-file-input'
                    id='customFileLang'
                    lang='pt-br'
                    disabled={this.state.disabled_upload}
                  />
                  <label className='custom-file-label' htmlFor='customFileLang'>
                    {this.state.nameFile}
                  </label>
                </div>
              </div>

              <div className={`input-area ${reCaptchaEnabled ? '' : 'deactivate-recaptcha' }`}>
                {window.REACT_APP_SITEKEY_RECAPTCHA ? (
                  <ReCAPTCHA 
                    sitekey={window.REACT_APP_SITEKEY_RECAPTCHA}
                    ref={this.recaptchaRef}
                    onChange={this.handleRecaptchaSuccess}
                    onExpired={this.handleRecaptchaResets}
                    onErrored={this.handleRecaptchaResets}
                  />
                ) : console.error("Couldn't load recaptcha. Please verify!")}
              </div>

              <div className='buttons-area'>
                <button
                  type='button'
                  onClick={this.onFormSubmit}
                  className='btn btn-primary '
                  id='upload-csv'
                  disabled={!(this.state.validateCaptcha && !this.state.disabled_upload) || this.state.isLoading}
                >
                  {this.state.isLoading ? (
                    <i className='fas fa-spinner fa-spin fa-duotone' style={{ paddingTop: 2.3 }} />
                  ): (
                    <i className='fas fa-cloud-upload-alt' />
                  )}
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ uploadImport: state.table.listImport, tableFilter: state.filter.tableFilter });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getListAllImports, setTableFilter }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UploadAdmin);
