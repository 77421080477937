import React, { Component } from "react";
import axios from 'axios';

import Modal from '../components/modal/Modal';

export default class FirstAccessTerms extends Component {
  constructor(props) {
    super(props)

    this.state = {
      acceptTerms: true,
    };
    this.termsRef = React.createRef();
    
    this.onHideModal = this.onHideModal.bind(this);
    this.handleAcceptTerms = this.handleAcceptTerms.bind(this);
    this.handleNoAcceptClose = this.handleNoAcceptClose.bind(this);
  }

  componentDidMount () {
    this.termsRef.current.innerHTML = this.props.termContent;
  }

  handleNoAcceptClose() {
    const BASE_URL = `${process.env.REACT_APP_URL}`;
    const userData = {
      firstAccess: "true"
    };

    axios.put(`${BASE_URL}/user/first-access`, userData)
    .then(() => {
      this.props.logout();
    })
    .catch((error) => {
      if (error.response && JSON.stringify(error.response.status) === '500') {
        this.props.logout();
      }
    });
  }

  onHideModal() {
    const BASE_URL = `${process.env.REACT_APP_URL}`;
    const userData = {
      firstAccess: "false"
    };

    axios.put(`${BASE_URL}/user/first-access`, userData)
    .then(() => {
      this.props.authHideModal();
    })
    .catch((error) => {
      if (error.response && JSON.stringify(error.response.status) === '500') {
        this.props.logout();
      }
    });
  }

  handleAcceptTerms() {
    this.setState({
      acceptTerms: !this.state.acceptTerms,
    });
  }

  render() {
    return (
        <>
          <Modal
              modal={this.props.modal}
              metodoHiden={this.onHideModal}
              handleNoAcceptClose={this.handleNoAcceptClose}
              tipo={this.props.tipo}
              acceptTerms={this.state.acceptTerms}
              termsRef={this.termsRef}
              handleAcceptTerms={this.handleAcceptTerms}
          />
        </>
    );
  }
}
