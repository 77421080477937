import axios from "axios";
const BASE_URL = `${process.env.REACT_APP_URL_MDI}`;

function getListAllImportsMDI() {
  const response = axios.get(`${BASE_URL}/cashback-files`);
  return {
    type: "LIST_IMPORTS_FETCHED",
    payload: response,
  };
}

function filterListImport(newtable) {
  return {
    type: "LIST_TABLE_FILTERED",
    payload: newtable,
  };
}

export {getListAllImportsMDI,filterListImport}