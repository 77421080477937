import React, { Component, Fragment } from "react";
import axios from "axios";

export default class SelectPartners extends Component {
  constructor(props) {
    super(props);

    this.handleSelectPartner = this.handleSelectPartner.bind(this);

    this.state = {
      partners: [],
      loadingPartners: true,
      showListSubParceiros: false,
      loadingMessage: "",
      partnerSelected: ""
    };
  }

  componentDidMount() {
    const BASE_URL = `${process.env.REACT_APP_URL}`;

    axios
      .get(`${BASE_URL}/partner`)
      .then((response) => {
        var partnersData = response.data.results;

        partnersData.sort(function (a, b) {
          if (a.partnerName < b.partnerName) {
            return -1;
          }
          if (a.partnerName > b.partnerName) {
            return 1;
          }
          return 0;
        });

        if (response.data.results) {
          !this.isCancelled &&
            this.setState({
              partners: partnersData,
              loadingPartners: false,
              loadingMessage: "",
            });
            this.props.handleLoadedPartners();
        } else {
          !this.isCancelled &&
            this.setState({
              partners: [],
              loadingPartners: false,
              loadingMessage: "Não há parceiros disponíveis",
            });
        }
      })
      .catch((error) => {
        if (error.response) {
          const httpStatusError = JSON.stringify(error.response.status);
          if (httpStatusError === "401") {
            this.props.logout();
          }
        }
      });
  }

  componentWillUnmount() {
    this.isCancelled = true;
  }

handleSelectPartner(event) {
    const partnerId = event.target.value;

    this.setState({ 
      partnerSelected: partnerId, 
      loadingSubPartners: true
    });

    this.props.handleSelectPartner(partnerId);
  };

  render() { 
    return (
        <Fragment>
            {this.state.loadingPartners === true ? (
                <div className="loading-msg">Carregando parceiros...</div>
            ) :
                this.props.renderType === "partnerCode" ?
                    (
                        <select value={this.state.partnerSelected} onChange={this.handleSelectPartner}>
                            <option> Selecione o parceiro </option>
                            {this.state.partners.map(({ partnerCode, partnerName }, index) => (
                                <option key={index} value={partnerCode}>
                                    {partnerName}
                                </option>
                            ))}
                        </select>
                    ) : <select value={this.state.partnerSelected} onChange={this.handleSelectPartner}>
                        <option> Selecione o parceiro </option>
                        {this.state.partners.map(({ id, partnerName }, index) => (
                            <option key={index} value={id}>
                                {partnerName}
                            </option>
                        ))}
                    </select>}

            {this.partners?.length === 0 ? (
                <div className="loading-msg">Não há parceiros cadastrados</div>
            ) : null}
        </Fragment>
    );
  }
}
