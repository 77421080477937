import React, { Component } from "react";
import axios from "axios";
import Loading from "./Loading";
import Messages from "./Messages";

export default class ModalCancelSelected extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            messageFail: false,
            messageSuccess: false,
            descriptionMessage: "Ocorreu um erro ao tentar realizar o cancelamento"
        }

        this.confirmCancelSelected = this.confirmCancelSelected.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
    }

    confirmCancelSelected() {
        const TRANSACTIONS_RELEASE_CANCEL = `${process.env.TRANSACTIONS_RELEASE_CANCEL}`;
        const commerceItems = this.props.releases.map(item => {
            return item.commerceItemId
        });

        this.setState({
            loading: true
        })
        axios
            ({
                method: 'POST',
                url: TRANSACTIONS_RELEASE_CANCEL,
                data: commerceItems
            })
            .then(response => {
                const data = response.data;
                console.log('then --', data);
                let messages = data.results.messages.join('<br />');
                if (data.results && data.results.success) {
                    this.setState({
                        loading: false,
                        messageSuccess: true,
                        messageFail: false,
                        descriptionMessage: messages
                    });
                } else {                    
                    this.setState({
                        loading: false,
                        messageSuccess: false,
                        messageFail: true,
                        descriptionMessage: messages
                    });
                }

                this.props.resetFilterAndTransactions();
                this.props.hideModal();
            })
            .catch(error => {
                this.setState({
                    loading: false,
                    messageSuccess: false,
                    messageFail: true,
                    descriptionMessage: "Ocorreu um erro ao tentar realizar o cancelamento"
                });
                
                this.props.resetFilterAndTransactions();
                this.props.hideModal();
            })
    }
    
    closeMessage() {
        this.setState({
            messageSuccess: false,
            messageFail: false
        });
    }

    render() {

        return (
            <>
                {this.props.showModal &&                    
                    <div className="-mdlcancellation-saleoff js-mdlcancellation-saleoff">
                    <div className="util-modal-background">
                        <div className="util-modal-center -modalbox">

                        <div className="-modalheader">
                            <h5 className="-title" id="cancelletionConfirmationSaleoff">Cancelamento</h5>
                            <div onClick={this.props.hideModal} className="js-closemdlcancellation-saleoff"><span className="icon-x"></span></div>
                        </div>

                        <div className="-modalcontent">
                            <div className="-containernoticewarning">
                            <p className="-textmain">Confirma o cancelamento do item/pedido abaixo:</p>
                            <ul>
                                {this.props.releases.map((item, index) => 
                                    <li key={index}>
                                        <p className="-text"><span>Item: </span>{item.commerceItemId}</p>
                                    </li>                                     
                                )}
                            </ul>
                            </div>

                            <div className="-containerbuttons">
                            <button onClick={this.confirmCancelSelected} type="button" className="btn">Confirmar</button>
                            <button onClick={this.props.hideModal} type="button" className="btn">Voltar</button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                }
                <Messages
                    messageSuccess={this.state.messageSuccess}
                    messageFail={this.state.messageFail}
                    descriptionMessage={this.state.descriptionMessage}
                    closeMessage={this.closeMessage} />
                <Loading loading={this.state.loading}/>
            </>
        )
    }
}