import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import EletronicExtract from "../views/eletronic-extract";
import Liquidation from "../views/liquidation";
import Home from "../views/home";
import axios from "axios";
import Keycloak from "keycloak-js";
const jwt = require("jsonwebtoken");

const keycloakJson = {
    url: `${process.env.REACT_APP_AUTH_SERVER_URL}`,
    realm: `${process.env.REACT_APP_REALM}`,
    clientId: `${process.env.REACT_APP_RESOURCE}`,
};

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keycloak: null,
            authenticated: false,
            isAdmin: false,
            isAdminCashbackFiles: false,
            isPartner: false,
            isBackoffice: false,
            isBackofficeCashbackFiles: false,
            isBackofficeGetnetReleasePayment: false,
            name: "",
            email: "",
            company: "",
            tipo: "",
            modal: false,
            termContent: "",
        };

        this.onHideModal = this.onHideModal.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.hasFirstAccessAttribute = this.hasFirstAccessAttribute.bind(this);
        this.getTermContent = this.getTermContent.bind(this);
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        const BASE_URL = `${process.env.REACT_APP_URL}`;

        const keycloak = Keycloak(keycloakJson);
        keycloak.init({ onLoad: "login-required" }).then((authenticated) => {
            axios.defaults.headers.common[
                "authorization"
            ] = `Bearer ${keycloak.token}`;

            axios.post(`${BASE_URL}/user-session/session-restrictor`)
                .then((response) => {})
                .catch((error) => { 
                    console.error('[session-restrictor][error]', error);
                    this.logout();
                });

            this.onOpenModal('loading');

            this.setState({
                keycloak: keycloak,
                refreshToken: keycloak.refreshToken,
                usuario: keycloak.userInfo,
                authenticated: authenticated,
            });

            const decryptedToken = jwt.decode(keycloak.token);
            if (decryptedToken.groups) {
                if (decryptedToken.groups.includes("ADMIN") || decryptedToken.groups.includes("admin")) {
                    this.setState({ isAdmin: true });
                } else if (decryptedToken.groups.includes("ADMIN_CASHBACK_FILES") || decryptedToken.groups.includes("admin_cashback_files")) {
                    this.setState({ isAdminCashbackFiles: true });
                } else if (decryptedToken.groups.includes("VIAVAREJO") || decryptedToken.groups.includes("viavarejo")) {
                    this.setState({ isPartner: true });
                } else if (decryptedToken.groups.includes("BACKOFFICE") || decryptedToken.groups.includes("backoffice")) {
                    this.setState({ isBackoffice: true });
                } else if (decryptedToken.groups.includes("BACKOFFICE-CASHBACK-FILES") || decryptedToken.groups.includes("backoffice-cashback-files")) {
                    this.setState({ isBackofficeCashbackFiles: true });
                } else if (decryptedToken.groups.includes("BACKOFFICE-GETNET-RELEASE-PAYMENT") || decryptedToken.groups.includes("backoffice-getnet-release-payment")) {
                    this.setState({ isBackofficeGetnetReleasePayment: true });
                } else {
                    this.setState({
                        company: decryptedToken.groups[0],
                    });
                }
            }

            keycloak.loadUserInfo().then((userInfo) => {
                this.setState({
                    name: userInfo.preferred_username,
                    email: userInfo.email,
                });
            });

            axios.post(`${BASE_URL}/user-session/account`)
                .then((response) => {
                    if (response && 
                        response.results && 
                        (response.results.firstAccess === null || response.results.firstAccess === undefined || response.results.firstAccess)
                    ) {
                        this.getTermContent(keycloak);
                        return;
                    }
                    setTimeout(() => {
                        this.onHideModal();
                    }, 500);
                })
                .catch((error) => {
                    console.error('[user-session][account][error]', error);
                    keycloak.logout();
                    this.logout();
                });
            });
    }

    logout() {
        const BASE_URL = `${process.env.REACT_APP_URL}`;

        axios.defaults.headers.common[
            "authorization"
        ] = `Bearer ${this.state.keycloak.token}`;
        axios.post(`${BASE_URL}/user-session/logout`)
            .then((response) => {this.state.keycloak.logout();})
            .catch((error) => {
                console.error('[user-session][logout][error]', error);
                this.state.keycloak.logout();
            });
    }


    onHideModal() {
        this.setState({
            tipo: '',
            modal: false,
        });
    }

    onOpenModal(type) {
        this.setState({
            tipo: type,
            modal: true,
        });
    }

    hasFirstAccessAttribute(data) {
        return data.firstAccess ? true : false;
    }

    getTermContent(keycloak) {
        const BASE_URL = `${process.env.REACT_APP_URL}`;

        axios
            .get(`${BASE_URL}/termo-condicao`)
            .then((response) => {
                if (response.data.results) {
                    this.setState({
                        termContent: response.data.results.description,
                    });
                } else {
                    this.setState({
                        termContent: '',
                    });
                }
                setTimeout(() => {
                    this.onOpenModal('firstaccess');
                }, 500);
            })
            .catch((error) => {
                console.error(error.response.status);
                keycloak.logout();
            });
    }

    render() {
        if (this.state.keycloak) {
            if (this.state.authenticated) {
                return (
                    <BrowserRouter>
                        <Switch>
                            <Route path="/eletronic-extract" exact={true} component={() => <EletronicExtract state={this.state} logout={this.logout} />} />
                            <Route path="/liquidation" exact={true} component={() => <Liquidation state={this.state} logout={this.logout} />} />
                            <Route path="/" exact={true} component={() => <Home state={this.state} onHideModal={this.onHideModal} logout={this.logout} />} />
                            <Route to="*" component={() => <Home state={this.state} onHideModal={this.onHideModal} logout={this.logout} />} />
                        </Switch>
                    </ BrowserRouter>
                );
            } else {
                return (
                    <BrowserRouter>
                        <Switch>
                            <Route path="/" exact={true} component={() => <Home state={this.state} onHideModal={this.onHideModal} logout={this.logout} />} />
                            <Route to="*" component={() => <Home state={this.state} onHideModal={this.onHideModal} logout={this.logout} />} />
                        </Switch>
                    </ BrowserRouter>
                );
            }
        }
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact={true} component={() => <Home state={this.state} onHideModal={this.onHideModal} logout={this.logout} />} />
                    <Route to="*" component={() => <Home state={this.state} onHideModal={this.onHideModal} logout={this.logout} />} />
                </Switch>
            </ BrowserRouter>
        );

    }
}

export default App;
