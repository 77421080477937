import React, { Component } from "react";
import axios from "axios";
import {sessionExpired, generalError} from '../../../helpers/GlobalMessages'
import SelectPartners from './../select/SelectPartners';


const initialState = {
  partner_name: "",
  partner_code: "",

  disabled_button: false,

  partner_error: "",
  partner_name_error: "",
  partner_code_error: "",
};
export default class FormSubPartner extends Component {
  constructor(props) {
    super(props);
    this.capitalizePartnerName = this.capitalizePartnerName.bind(this);
    this.state = {
        loadedPartners: false
    };
  }

  state = initialState;

  validate = () => {
    let partner_error = "";
    let partner_name_error = "";
    let partner_code_error = "";
    let isValidSubpartnerName = !!this.state.partner_name && (/^[a-zA-Z\s]+$/).test(this.state.partner_name);
    let isValidSubpartnerCode = !!this.state.partner_code && (/^[a-zA-Z]*[a-zA-Z]+[a-zA-Z]*$/.test(this.state.partner_code));
    
    if (!this.state.partnerId || this.state.partnerId === "Selecione o parceiro") {
      partner_error = "Selecione um parceiro";
    }

    if (!this.state.partner_name) {
      partner_name_error = "Nome do subparceiro inválido";
    } else if (
      this.state.partner_name && !isValidSubpartnerName
    ) {
       partner_name_error = "O nome do subparceiro deve conter somente letras";
    }

    if (!this.state.partner_code) {
      partner_code_error = "Código do subparceiro inválido";
    } else if (this.state.partner_code.length < 3) {
      partner_code_error = "O código do subparceiro deve conter 3 caracteres";
    } else if (
      this.state.partner_code.length === 3 && !isValidSubpartnerCode
    ) {
      partner_code_error = "O código do subparceiro deve conter somente letras";
    }

    if (partner_error || partner_name_error || partner_code_error) {
      this.setState({
        partner_error,
        partner_name_error,
        partner_code_error,
      });
      return false;
    }
    return true;
  };

  handleLoadedPartners = () => {
    this.setState({ loadedPartners: true });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const isValid = this.validate();

    if (isValid) {
      event.preventDefault();

      this.capitalizePartnerName().then(() => {
        if (this.state.disabled_button) {
          return;
        }

        this.setState({
          disabled_button: true,
        });

        const config = {
          headers: {
            "content-type": "application/json",
          },
        };
        
        const partner = {
          partnerCode: this.state.partnerId,
          subPartner: {
            subPartnerName: this.state.partner_name,
            subPartnerCode: this.state.partner_code,
          }
        };

        const BASE_URL = `${process.env.REACT_APP_URL}`;

        axios
          .post(`${BASE_URL}/partner/location/save`, partner, config)
          .then((response) => {
            this.setState(initialState);
            document.getElementById("form-container").reset();
            this.props.handleSendFormPartnerSuccess(
              "Subparceiro cadastrado com sucesso!"
            );
          })
          .catch((error) => {
            this.setState(initialState);
            document.getElementById("form-container").reset();

            if (error.response) {
              const httpStatusError = JSON.stringify(error.response.status);
              if (httpStatusError === '401') {
                this.props.handleSendFormPartnerFail(sessionExpired());
                return;
              } else {
                const message = error.response?.data?.results?.userMessage;
                if (message) {
                  this.props.handleSendFormPartnerFail(message);
                  return;
                }
              }
            }
            this.props.handleSendFormPartnerFail(generalError());
          });
      });
    }
  };

  handleSubpartnerNameInputChange = (event) => {
    event.preventDefault();
    this.setState({
      partner_name: event.target.value,
      partner_name_error: "",
      successMsg: "",
    });
  };

  async capitalizePartnerName() {
    this.setState({
      partner_name:
        this.state.partner_name[0].toUpperCase() +
        this.state.partner_name.substr(1),
    });
  }

  handleSubpartnerCodeInputChange = (event) => {
    event.preventDefault();
    this.setState({
      partner_code: event.target.value.toUpperCase(),
      successMsg: "",
    });
    if (event.target.value.length === 3) {
      this.setState({
        partner_code_error: "",
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.partnerId !== prevState.partnerId && 
      (!this.state.partnerId || this.state.partnerId !== "Selecione o parceiro")
    ) {
      this.setState({
        partner_error: "",
      });
    }
  }

  render() {
    return (
      <div id="page-wrapper">
        <div className="container">
          <SelectPartners 
            handleSelectPartner={partnerId => this.setState({ partnerId: partnerId })} handleLoadedPartners={this.handleLoadedPartners} renderType="partnerCode"
          />
          <div className="error-msg">{this.state.partner_error}</div>

          {this.state.loadedPartners && <form
            id="form-container"
            className="container"
            onSubmit={this.handleSubmit}
          >
            <div className="input-block">
              <label htmlFor="partner_name">Nome do subparceiro</label>
              <input
                type="text"
                id="partner_name"
                name="partner_name"
                onChange={this.handleSubpartnerNameInputChange}
                maxLength="255"
              />
            </div>
            <div className="error-msg">{this.state.partner_name_error}</div>

            <div className="input-block">
              <label htmlFor="partner_code">Código do subparceiro</label>
              <span> (3 caracteres)</span>
              <input
                type="text"
                id="partner_code"
                name="partner_code"
                onChange={this.handleSubpartnerCodeInputChange}
                maxLength="3"
              />
            </div>
            <div className="error-msg">{this.state.partner_code_error}</div>

            <div className="button-wrapper">
              <button
                className="button-admin"
                id="send-form"
                disabled={this.state.disabled_button}
              >
                {this.state.disabled_button ? "Enviando..." : "Enviar"}
              </button>
            </div>
          </form>}
        </div>
              
      </div>
    );
  }
}
