import React, { Component } from "react";
import axios from "axios";

import FormCooperatorEdit from "../../financial/form/FormCooperatorEdit";

export default class ListCooperator extends Component {
  constructor(props) {
    super(props);
    this.showEditCooperatorForm = this.showEditCooperatorForm.bind(this);

    this.state = {
      cooperators: [],
      selected_cooperator: {},

      editFormVisible: false,
      campaignsMessage: "",
    };
  }

  componentDidMount() {
    const BASE_URL = `${process.env.REACT_APP_URL}`;

    axios
      .get(`${BASE_URL}/collaborator`)
      .then((response) => {
        var partnersData = response.data.results;
        if (response.data.results) {
          this.setState({
            cooperators: partnersData,
          });
        } else {
          this.setState({
            cooperators: [],
          });
        }
      })
      .catch((error) => {
        if (error.response && JSON.stringify(error.response.status) === "401") {
          this.props.logout();
        }
      });
  }

  showEditCooperatorForm(cooperator) {
    this.setState({
      editFormVisible: true,
      selected_cooperator: cooperator,
    });
  }

  render() {
    return (
      <div>
        {this.state.editFormVisible === false ? (
          <div id="page-wrapper">
            <div id="list-container">
              {!this.state.cooperators.length ? (
                <div className="loading-msg">Não há colaboradores cadastrados</div>
              ) : (
                <div id="select-box-list-campaigns">
                  <div>
                    <table className="table-list" id="table-campaigns">
                      <thead>
                        <tr>
                          <th>Nome do colaborador</th>
                          <th>CPF</th>
                          <th>Email do colaborador</th>
                          <th>Ação</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.cooperators.map((cooperator) => {
                          return (
                            <tr key={cooperator.id}>
                              <td>{cooperator.name}</td>
                              <td>{cooperator.identifier ? cooperator.identifier : '---'}</td>
                              <td>{cooperator.email}</td>
                              <td>
                                <button
                                className="button-edit"
                                onClick={() =>
                                  this.showEditCooperatorForm(cooperator)
                                }
                                >
                                Editar
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
            
            <div className='button-wrapper-cooperator'>
                <button
                  className='button-admin'
                  onClick={this.props.handleConfigFormCreateCooperatorClick}
                >
                  Cadastrar Novo Colaborador
                </button>
              </div>
          </div>
        ) : (
          <FormCooperatorEdit
            cooperator={this.state.selected_cooperator}
            handleSendFormCooperatorSuccess={this.props.handleSendFormCooperatorSuccess}
            handleSendFormCooperatorFail={this.props.handleSendFormCooperatorFail}
          />
        )}
      </div>
    );
  }
}
