import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MDBDataTable } from "mdbreact";
import axios from "axios";

import { getListImport, getListAllImports, filterListImport } from "./TableActions";
import TableFilters from "./filters/TableFilters";
import ManualPagination from "./utils/ManualPagination";
import {setCampaignCode} from "./filters/FilterActionss";

const noData = "Não encontrado";

class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imports: [],
      modal: false,
      titulo: "",
      subtitulo: "",
      codeError: "",
      tipo: "",
      userLogged: "",
    };

    this.props.keycloak.loadUserInfo().then(userInfo => {
      this.setState({ userLogged: userInfo.preferred_username })
    });

    this.componentDidMount = this.componentDidMount.bind(this);
    this.getFileImport = this.getFileImport.bind(this);
    this.handleDefaultTable = this.handleDefaultTable.bind(this);
    this.setDatatable = this.setDatatable.bind(this);
    this.getDefaultTable = this.getDefaultTable.bind(this);
  }

  componentDidMount() {
    this.getDefaultTable();
  }

  getFileImport(data) {
    const BASE_URL = `${process.env.REACT_APP_URL}`;

    const headers = {
      responseType: "blob",
      nomeArquivo: data.nameFile,
    };

    axios({
      url: `${BASE_URL}/file`,
      method: "GET",
      headers,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", data.nameFile);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        if (error.response && JSON.stringify(error.response.status) === "401") {
          this.props.logout();
        }
      });
  }

  getFileOriginal(data) {
    const BASE_URL = `${process.env.REACT_APP_URL}`;

    const headers = {
      responseType: "blob",
      nomeArquivo: data.originalFileName,
    };

    axios({
      url: `${BASE_URL}/file/original/${data.code}`,
      method: "GET",
      headers,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", data.originalFileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        if (error.response && JSON.stringify(error.response.status) === "401") {
          this.props.logout();
        }
      });
  }

  setDatatable (newtable) {
    if (typeof newtable === 'function') return this.props.filterListImport(newtable());
    return this.props.filterListImport(newtable);
  }

  formatCampaignNameColumn(importacao) {
    return (
      <ul className="no-bullets">
        <li>
          Nome: {importacao.nameCampaign ? importacao.nameCampaign : noData}
        </li>
        <li>
          Código: {importacao.campaignCode ? importacao.campaignCode : noData}
        </li>
      </ul>
    )
  }

  formatOriginalFileColumn(importacao, i) {
    return (
      <ul className="no-bullets">
        <li>
          Nome: {importacao.originalFileName ? importacao.originalFileName : noData}
        </li>
        <li>
          Data da Importação: {importacao.dateImport ? importacao.dateImport : noData}
        </li>
        <button
          key={i}
          onClick={() => this.getFileOriginal(importacao)}
          className="btn btn-danger"
        >
          <i className="fas fa-cloud-download-alt ml-1" />
        </button>
      </ul>
    )
  }

  formatProcessedFileColumn(importacao, i) {
    return (
      <ul className="no-bullets">
        <li>
          Nome: {importacao.nameFile ? importacao.nameFile : noData}
        </li>
        <li>
          Status: {importacao.statusProcessed ? importacao.statusProcessed : noData}
        </li>
        <li>
          Total de Pontos Processados: {importacao.totalPointsProcessed ? importacao.totalPointsProcessed : noData}
        </li>
        <li>
          Data de processamento: {importacao.dateFileProcessed ? importacao.dateFileProcessed : noData}
        </li>
        <button
          key={i}
          disabled={!importacao.flagArquivoProcessado}
          onClick={() => this.getFileImport(importacao)}
          className={`${!importacao.flagArquivoProcessado && "btn btn-danger"} ${importacao.flagArquivoProcessado && "btn btn-success"}`}
        >
          <i className="fas fa-cloud-download-alt ml-1" />
        </button>
      </ul>
    )
  }

  getDefaultTable() {
    const userGroups = this.props.keycloak.idTokenParsed.groups;

    if(this.props.campaignCode !== '') {
      return;
    }

    if(userGroups.includes("ADMIN") || userGroups.includes("admin")) {
      return this.props.getListAllImports();
    }
    else {
      return this.props.getListImport();
    }
  }

  handleDefaultTable () {
    return {
      rows: [
        ...this.props.listImport.map((importacao, i) => ({
          userName: importacao.userName,
          nameCampaign: importacao.nameCampaign,
          namePartner: importacao.namePartner,
          codePartner: importacao.codePartner,
          nameFile: importacao.nameFile,
          dateImport: importacao.dateImport,
          originalFileName: importacao.originalFileName,
          campaignCode: importacao.campaignCode,
          flagArquivoProcessado: importacao.flagArquivoProcessado,
          statusProcessed: importacao.statusProcessed,
          totalPointsProcessed: importacao.totalPointsProcessed,
          dateFileProcessed: importacao.dateFileProcessed,
        })),
      ],
    };
  };

  render() {
    const data = {
      columns: [
        {
          label: "Nome",
          field: "userName",
          sort: 'disabled',
          width: 100,
        },
        {
          label: "Campanha",
          field: "campaign",
          sort: 'disabled',
          width: 220,
        },
        {
          label: "Parceiro",
          field: "namePartner",
          sort: 'disabled',
          width: 120,
        },
        {
          label: "Arquivo Original",
          field: "fileNameOriginal",
          sort: 'disabled',sort: 'disabled',
          width: 180,
        },
        {
          label: "Arquivo Processado",
          field: "fileNameProcessed",
          sort: 'disabled',
          width: 180,
        },
      ],
      rows: [
        ...this.props.listImport.map((importacao, i) => ({
          userName: importacao.userName,
          campaign: this.formatCampaignNameColumn(importacao),
          namePartner: importacao.namePartner ? importacao.namePartner : noData,
          fileNameOriginal: this.formatOriginalFileColumn(importacao, i),
          fileNameProcessed: this.formatProcessedFileColumn(importacao, i),
          dateImport: importacao.dateImport,
          originalFileName: importacao.originalFileName,
          campaignCode: importacao.campaignCode,
          nameCampaign: importacao.nameCampaign,
          codePartner: importacao.codePartner,
          nameFile: importacao.nameFile,
          flagArquivoProcessado: importacao.flagArquivoProcessado,
          statusProcessed: importacao.statusProcessed,
          totalPointsProcessed: importacao.totalPointsProcessed,
          dateFileProcessed: importacao.dateFileProcessed,
        })),
      ],
    };

    return (
      <div className="row wow fadeIn">
        <div className="col-md-12 mb-4 table-upoloads">
          <div className="card mb-4" id="table-container">
            <div className="card-header text-center ">
              Lista de Importações / Download de arquivos importados
            </div>
            <div className="card-body">
              <TableFilters 
                handleDefaultTable={this.handleDefaultTable}
                setDatatable={this.setDatatable}
                getDefaultTable={this.getDefaultTable}
                userLogged={this.state.userLogged}
                keycloak={this.props.keycloak}
              />
              <MDBDataTable
                responsive={true}
                hover
                noRecordsFoundLabel="Nenhum resultado encontrado."
                paginationLabel={["Anterior", "Próximo"]}
                searchLabel="Pesquisar"
                entriesLabel="Exibir"
                infoLabel={["Mostrando", "-", "de", "."]}
                entriesOptions={[5, 10, 20, 50, 100, 200, 400]}
                data={data}
              />
              <ManualPagination />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ listImport: state.table.listImport, campaignCode: state.filter.campaignCode });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getListImport, getListAllImports, filterListImport, setCampaignCode }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Table);
