import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MDBDataTable } from "mdbreact";
import axios from "axios";

import { getListAllImportsMDI, filterListImport } from "./TableActions";
import TableFilters from "./filters/TableFilters";
import ManualPagination from "./utils/ManualPagination";
import {setCampaignCode} from "./filters/FilterActionss";
import Modal from '../modal/Modal.jsx';

const noData = "Não encontrado";

class TableIncentiveEngine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imports: [],
      modal: false,
      titulo: "",
      subtitulo: "",
      codeError: "",
      tipo: "",
      userLogged: "",
    };

    this.props.keycloak.loadUserInfo().then(userInfo => {
      this.setState({ userLogged: userInfo.name })
    });

    this.componentDidMount = this.componentDidMount.bind(this);
    this.getFileImport = this.getFileImport.bind(this);
    this.handleDefaultTable = this.handleDefaultTable.bind(this);
    this.setDatatable = this.setDatatable.bind(this);
    this.getDefaultTable = this.getDefaultTable.bind(this);
    this.onHiden = this.onHiden.bind(this);
  }

  componentDidMount() {
    this.getDefaultTable();
  }

  getFileImport(data) {
    const BASE_URL = `${process.env.REACT_APP_URL_MDI}`;

    const headers = {
      responseType: "blob",
      nomeArquivo: data.originalFileName,
    };
    axios({
      url: `${BASE_URL}/cashback-files/return/download?fileName=${data.importFileName}`,
      method: "GET",
      headers,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", data.importFileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        if (error.response && JSON.stringify(error.response.status) === "401") {
          this.props.logout();
        } else if (typeof error.response.data.results.userMessage === 'undefined') {
            this.setState({
                modal: true,
                titulo:
                    'Erro inesperado entre em contato com nossos canais de atendimento',
                codeError: error.response.data.results.code,
                tipo: 'error',
            });
        } else {
            this.setState({
                modal: true,
                titulo: error.response.data.results.userMessage,
                codeError: error.response.data.results.code,
                tipo: 'error',
            });
        }
      });
  }

  doDeleteFile(data) {
    const BASE_URL = `${process.env.REACT_APP_URL_MDI}`;

    const headers = {
      responseType: "blob",
      nomeArquivo: data.originalFileName,
    };
    axios({
      url: `${BASE_URL}/cashback-files/import?fileName=${data.importFileName}`,
      method: "DELETE",
      headers,
    })
      .then((response) => {
        this.getDefaultTable();
        this.setState({
          modal: true,
          titulo:
              'Arquivo deletado com sucesso',
          tipo: 'sucessoDLT',
      });
      })
      .catch((error) => {
        if (error.response && JSON.stringify(error.response.status) === "401") {
          this.props.logout();
        } else if (typeof error.response.data.results.userMessage === 'undefined') {
            this.setState({
                modal: true,
                titulo:
                    'Erro inesperado entre em contato com nossos canais de atendimento',
                codeError: error.response.data.results.code,
                tipo: 'error',
            });
        } else {
            this.setState({
                modal: true,
                titulo: error.response.data.results.userMessage,
                codeError: error.response.data.results.code,
                tipo: 'error',
            });
        }
      });
  }

  doProcessFile(data, i) {
    const BASE_URL = `${process.env.REACT_APP_URL_MDI}`;

    const headers = {
      responseType: "blob",
      nomeArquivo: data.originalFileName,
    };
    axios({
      url: `${BASE_URL}/cashback-files/process-file?fileName=${data.importFileName}`,
      method: "POST",
      headers,
    })
      .then((response) => {
        const button = document.getElementById(`btn-value-${i}`);
        button.disabled = true;
        this.setState({
          modal: true,
          titulo:
              'Arquivo processado com sucesso!',
          tipo: 'sucessoPCS',
      });
      })
      .catch((error) => {
        if (error.response && JSON.stringify(error.response.status) === "401") {
          this.props.logout();
        } else if (typeof error.response.data.results.userMessage === 'undefined') {
            this.setState({
                modal: true,
                titulo:
                    'Erro inesperado entre em contato com nossos canais de atendimento',
                codeError: error.response.data.results.code,
                tipo: 'error',
            });
        } else {
            this.setState({
                modal: true,
                titulo: error.response.data.results.userMessage,
                codeError: error.response.data.results.code,
                tipo: 'error',
            });
        }
      });
  }

  getFileOriginal(data) {
    const BASE_URL = `${process.env.REACT_APP_URL_MDI}`;

    const headers = {
      responseType: "blob",
      nomeArquivo: data.originalFileName,
    };
    axios({
      url: `${BASE_URL}/cashback-files/import/download?fileName=${data.importFileName}`,
      method: "GET",
      headers,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", data.importFileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        if (error.response && JSON.stringify(error.response.status) === "401") {
          this.props.logout();
        } else if (typeof error.response.data.results.userMessage === 'undefined') {
            this.setState({
                modal: true,
                titulo:
                    'Erro inesperado entre em contato com nossos canais de atendimento',
                codeError: error.response.data.results.code,
                tipo: 'error',
            });
        } else {
            this.setState({
                modal: true,
                titulo: error.response.data.results.userMessage,
                codeError: error.response.data.results.code,
                tipo: 'error',
            });
        }
      });
  }

  setDatatable (newtable) {
    if (typeof newtable === 'function') return this.props.filterListImport(newtable());
    return this.props.filterListImport(newtable);
  }

  formatCampaignNameColumn(importacao) {
    return (
      <ul className="no-bullets">
        <li>
          Nome: {importacao.campaignName ? importacao.campaignName : noData}
        </li>
        <li>
          Código: {importacao.campaignCode ? importacao.campaignCode : noData}
        </li>
      </ul>
    )
  }

  formatCampaignPartnerColumn(importacao) {

    return (
      <ul className="no-bullets">
        <li>
          {importacao.partnerCode ? importacao.partnerCode.toUpperCase() : noData}
        </li>
        <li>
          {importacao.partnerName ? importacao.partnerName : ""}
        </li>
      </ul>
    )
  }

  formatOriginalFileColumn(importacao, i) {
    return (
      <ul className="no-bullets">
        <li>
          Nome: {importacao.importFileName ? importacao.importFileName : noData}
        </li>
        <li>
          Nome original: {importacao.sourceImportFileName ? importacao.sourceImportFileName : noData}
        </li>
        <li>
          Data da Importação: {importacao.importDate ? importacao.importDate : noData}
        </li>
        <button
          key={i}
          onClick={() => this.getFileOriginal(importacao)}
          className="btn btn-primary"
        >
          <i className="fas fa-cloud-download-alt ml-1" />
        </button>
      </ul>
    )
  }

  formatProcessedFileColumn(importacao, i) {
    return (
      <ul className="no-bullets">
        <button
          key={i}
          onClick={() => this.getFileImport(importacao)}
          className={"btn btn-warning"}
          disabled = {importacao.importFileStatus === "PENDING" || importacao.importFileStatus === "DELETED"}
        >
          <i className="fas fa-cloud-download-alt ml-1" />
        </button>
      </ul>
    )
  }

  deleteImportFile(importacao, i) {
    return (
      <ul className="no-bullets">
        <button
          key={i}
          onClick={() => this.doDeleteFile(importacao)}
          className={"btn btn-danger"}
          disabled = {importacao.importFileStatus !== "PENDING"}
        >
          <i className="fas fa-trash ml-1" />
        </button>
      </ul>
    )
  }

  processImportFile(importacao, i) {    
    return (
      <ul className="no-bullets">
        <button
         id={`btn-value-${i}`}
          key={i}
          onClick={() => this.doProcessFile(importacao, i)}
          className={"btn btn-success"}
          disabled = {!(importacao.importFileStatus === "PENDING" && importacao.importFileType === "PROCESSABLE_IMPORT")}
        >
          <i className="fas fa-hammer ml-1" />
        </button>
      </ul>
    )
  }

  getDefaultTable() {
    if(!this.props.isAdminCashbackFiles && !this.props.isAdmin && !this.props.isBackofficeCashbackFiles) return;
    return this.props.getListAllImportsMDI();
  }

  handleDefaultTable () {
    return {
      rows: [
        ...this.props.listImport.map((importacao, i) => {
            return ({
                sourceUser: importacao.sourceUser  || "-",
                sourceImportFileName: importacao.sourceImportFileName,
                importFileName: importacao.importFileName,
                importFileStatus: importacao.importFileStatus,
                importDate: importacao.importDate,
                partnerCode: importacao.partnerCode,
                partnerName: importacao.partnerName,
                campaignCode: importacao.campaignCode,
                campaignName: importacao.campaignName
            })
        }
        ),
      ],
    };
  };

  onHiden() {
    this.setState({
      modal: false,
      titulo: '',
      subtitulo: '',
      codeError: '',
      tipo: '',
    });
  }

  render() {
    const data = {
      columns: [
        {
          label: "Nome",
          field: "userName",
          sort: 'disabled',
          width: 100,
        },
        {
          label: "Campanha",
          field: "campaign",
          sort: 'disabled',
          width: 220,
        },
        {
          label: "Parceiro",
          field: "namePartner",
          sort: 'disabled',
          width: 140,
        },
        {
          label: "Arquivo Original",
          field: "fileNameOriginal",
          sort: 'disabled',sort: 'disabled',
          width: 140,
        },
        {
          label: "Arquivo de Retorno",
          field: "fileNameProcessed",
          sort: 'disabled',
          width: 180,
        },
        {
          label: "Deletar",
          field: "deleteFile",
          sort: 'disabled',
          width: 180,
        }
      ],
      rows: [
        ...this.props.listImport.map((importacao, i) => ({
          userName: importacao.sourceUser || "-",
          campaign: this.formatCampaignNameColumn(importacao),
          namePartner: importacao.partnerCode ? this.formatCampaignPartnerColumn(importacao) : noData,
          fileNameOriginal: this.formatOriginalFileColumn(importacao, i),
          fileNameProcessed: this.formatProcessedFileColumn(importacao, i),
          deleteFile: this.deleteImportFile(importacao, i),
          processFile: this.processImportFile(importacao, i),
          dateImport: importacao.dateImport,
          originalFileName: importacao.originalFileName,
          campaignCode: importacao.campaignCode,
          nameCampaign: importacao.nameCampaign,
          codePartner: importacao.codePartner,
          nameFile: importacao.nameFile,
          flagArquivoProcessado: importacao.flagArquivoProcessado,
          statusProcessed: importacao.statusProcessed,
          totalPointsProcessed: importacao.totalPointsProcessed,
          dateFileProcessed: importacao.dateFileProcessed,
        })),
      ],
    };
    if(this.props.isAdminCashbackFiles || this.props.isAdmin) data.columns.push({ label: "Processar", field: "processFile", sort: 'disabled', width: 180 });

    return (
      <div className="row wow fadeIn">
        {this.state.modal ? (
          <Modal
            modal={this.state.modal}
            metodoHiden={this.onHiden}
            titulo={this.state.titulo}
            codeError={this.state.codeError}
            tipo={this.state.tipo}
            subtitulo={this.state.subtitulo}
            validationErrors={this.state.validation_errors}
          />
        ) : null}
        <div className="col-md-12 mb-4 table-upoloads">
          <div className="card mb-4" id="table-container">
            <div className="card-header text-center ">
              Lista de Importações / Download de arquivos importados
            </div>
            <div className="card-body">
              <TableFilters 
                handleDefaultTable={this.handleDefaultTable}
                setDatatable={this.setDatatable}
                getDefaultTable={this.getDefaultTable}
                userLogged={this.state.userLogged}
                keycloak={this.props.keycloak}
              />
              <MDBDataTable
                responsive={true}
                hover
                noRecordsFoundLabel="Nenhum resultado encontrado."
                paginationLabel={["Anterior", "Próximo"]}
                searchLabel="Pesquisar"
                entriesLabel="Exibir"
                infoLabel={["Mostrando", "-", "de", "."]}
                entriesOptions={[5, 10, 20, 50, 100, 200, 400]}
                data={data}
              />
              <ManualPagination />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ listImport: state.table.listImport, campaignCode: state.filter.campaignCode });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getListAllImportsMDI, filterListImport, setCampaignCode }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(TableIncentiveEngine);
