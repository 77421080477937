import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

import { getListAllImportsMDI } from '../table/TableActions.jsx';
import { setTableFilter } from '../table/filters/FilterActionss.jsx';

import Modal from '../modal/Modal.jsx';

class UploadAdminIncentiveEngine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            file: null,
            nameFile: 'Selecione um arquivo CSV',
            modal: false,
            titulo: '',
            subtitulo: '',
            codeError: '',
            tipo: '',
            parsedCsvFile: {},
            token: '',
            validateCaptcha: false,

            partners: [],
            partner: {},
            partner_name: '',
            partner_code: '',
            campaign_name: '',
            campaign_code: '',
            partner_campaigns: [],
            validation_errors: [],

            loading_message: 'Carregando parceiros...',
            no_campaigns_message: false,
            disabled_select: true,
            disabled_select_campaign: true,
            disabled_upload: true,
            validateLoader: false,
            uploadLoader: false,
        };

        this.recaptchaRef = React.createRef();

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onValidateFormSubmit = this.onValidateFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onHiden = this.onHiden.bind(this);
        this.getAsText = this.getAsText.bind(this);
        this.fileReadingFinished = this.fileReadingFinished.bind(this);
        this.processData = this.processData.bind(this);
    }

    handleRecaptchaSuccess = (token) => {
        this.setState({
            token: token,
            validateCaptcha: true,
        })
    }

    handleRecaptchaResets = () => {
        this.setState({
            token: '',
            validateCaptcha: false,
        })
    }

    breakErrorMessage = (errorTitleAndMessage) =>{
        var regex = /:(.+)/; 
        var match = errorTitleAndMessage.match(regex);

        if (match) {
            console.log(match);
            var index = match.index; 
            var error = errorTitleAndMessage.slice(0, index).trim();
            var message = errorTitleAndMessage;

            return [error, message];
        }
        return [errorTitleAndMessage];
    }

    onFormSubmit(e) {
        if (this.state.file != null) {
            e.preventDefault();

            var object_campaigns = this.state.parsedCsvFile;

            var rows = [Object.keys(object_campaigns[0])];

            object_campaigns.forEach((campaign_object) => {
                const values = Object.values(campaign_object);
                rows.push(values);
            });

            let csvContent = rows.map((e) => e.join(';')).join('\n');

            let csvFile = new Blob([csvContent], {
                encoding: 'UTF-8',
                type: 'text/plain;charset=UTF-8',
            });

            this.fileUpload(csvFile)
                .then((resp) => {
                    this.recaptchaRef.current.reset();
                    this.handleRecaptchaResets();
                    this.onClear();
                    this.setState({ modal: true, tipo: 'sucessoMDI', uploadLoader:false });
                    this.props.getListAllImportsMDI();
                    this.props.setTableFilter("normal");
                    document.getElementById("user-filter-checkbox").checked = false;
                    document.getElementById("date-filter-checkbox").checked = false;
                })
                .catch((error) => {
                    this.recaptchaRef.current.reset();
                    this.handleRecaptchaResets();
                    this.onClear();
                    if (!error.response.data.results) {
                        this.setState({
                            modal: true,
                            uploadLoader:false,
                            titulo:
                                'Sua sessão expirou. Recarregue a página e tente novamente.',
                            codeError: null,
                            tipo: 'error',
                        });
                    } else if (typeof error.response.data.results.userMessage === 'undefined') {
                        this.setState({
                            modal: true,
                            uploadLoader:false,
                            titulo:
                                'Erro inesperado entre em contato com nossos canais de atendimento',
                            codeError: error.response.data.results.code,
                            tipo: 'error',
                        });
                    } else {
                        const errorTitleAndMessage = this.breakErrorMessage(error.response.data.results.userMessage);
                        const errorTitle = errorTitleAndMessage[0];
                        const errorMessage = errorTitleAndMessage.length > 1 ? errorTitleAndMessage[1] : "";

                        this.setState({
                            modal: true,
                            uploadLoader:false,
                            titulo: errorTitle,
                            codeError: errorMessage,
                            tipo: 'errorMDI',
                        });
                    }
                });

                this.setState({uploadLoader:true})

        } else {
            this.onClear();
            this.setState({
                modal: true,
                uploadLoader:false,
                titulo: 'Selecione um arquivo csv para o envio!',
                subtitulo: 'Favor baixar o arquivo csv de modelo.',
                tipo: 'alerta',
            });
        }
    }

    onValidateFormSubmit(e) {
        if (this.state.file != null) {
            e.preventDefault();

            var object_campaigns = this.state.parsedCsvFile;

            var rows = [Object.keys(object_campaigns[0])];

            object_campaigns.forEach((campaign_object) => {
                const values = Object.values(campaign_object);
                rows.push(values);
            });

            let csvContent = rows.map((e) => e.join(';')).join('\n');

            let csvFile = new Blob([csvContent], {
                encoding: 'UTF-8',
                type: 'text/plain;charset=UTF-8',
            });

            this.validateFileUpload(csvFile)
                .then((resp) => {
                    this.recaptchaRef.current.reset();
                    this.handleRecaptchaResets();
                    this.onClear();
                    this.setState({ modal: true, tipo: 'sucessoVLD', validateLoader:false });
                    this.props.getListAllImportsMDI();
                    this.props.setTableFilter("normal");
                    document.getElementById("user-filter-checkbox").checked = false;
                    document.getElementById("date-filter-checkbox").checked = false;
                })
                .catch((error) => {
                    this.recaptchaRef.current.reset();
                    this.handleRecaptchaResets();
                    this.onClear();
                    if (!error.response.data.results) {
                        this.setState({
                            modal: true,
                            validateLoader:false,
                            titulo:
                                'Sua sessão expirou. Recarregue a página e tente novamente.',
                            codeError: null,
                            tipo: 'error',
                        });
                    } else if (typeof error.response.data.results.userMessage === 'undefined') {
                        this.setState({
                            modal: true,
                            validateLoader:false,
                            titulo:
                                'Erro inesperado entre em contato com nossos canais de atendimento',
                            codeError: error.response.data.results.code,
                            tipo: 'error',
                        });
                    } else {
                        const errorTitleAndMessage = this.breakErrorMessage(error.response.data.results.userMessage);
                        const errorTitle = errorTitleAndMessage[0];
                        const errorMessage = errorTitleAndMessage.length > 1 ? errorTitleAndMessage[1] : "";

                        this.setState({
                            modal: true,
                            validateLoader:false,
                            titulo: errorTitle,
                            codeError: errorMessage,
                            tipo: 'errorMDI',
                        });
                    }
                });

                this.setState({validateLoader:true});
        } else {
            this.onClear();
            this.setState({
                modal: true,
                validateLoader:false,
                titulo: 'Selecione um arquivo csv para o envio!',
                subtitulo: 'Favor baixar o arquivo csv de modelo.',
                tipo: 'alerta',
            });
        }
    }

    fileUpload(file) {
        const BASE_URL = `${process.env.REACT_APP_URL_MDI}`;
        const formData = new FormData();

        formData.append('file', file, this.state.nameFile);

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        return axios.post(`${BASE_URL}/cashback-files/upload`, formData, config);
    }

    validateFileUpload(file) {
        const BASE_URL = `${process.env.REACT_APP_URL_MDI}`;
        const formData = new FormData();

        formData.append('file', file, this.state.nameFile);

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        return axios.post(`${BASE_URL}/cashback-files/upload/validate`, formData, config);
    }

    getFileExtension(filename) {
        return filename.split('.').pop();
    }

    getAsText(rawFile) {
        const reader = new FileReader();
        reader.readAsText(rawFile);
        reader.onload = this.fileReadingFinished;
    }

    fileReadingFinished(e) {
        var csv = e.target.result;
        this.processData(csv);
    }

    processData(csv) {
        const allTextLines = csv.split(/\r\n|\n/);
        const col_names = allTextLines[0].split(';');
        const lines_number = allTextLines.length;

        var campaigns = [];

        var line_index;

        for (line_index = 1; line_index < lines_number; line_index++) {
            const campaign = {};
            const line_data = allTextLines[line_index].split(';');

            col_names.forEach((column_name, column_index) => {
                campaign[column_name] = line_data[column_index];
            });

            campaigns.push(campaign);
        }

        this.setState({
            parsedCsvFile: campaigns,
        });
    }

    onChange(e) {
        if (typeof e.target.files[0] != 'undefined') {
            const retorno = this.getFileExtension(e.target.files[0].name);
            if (retorno === 'csv') {
                this.setState({
                    file: e.target.files[0],
                    nameFile: e.target.files[0].name,
                });
                this.getAsText(e.target.files[0]);
            } else {
                this.onClear();
                this.setState({
                    modal: true,
                    titulo: 'Permitido apenas arquivo CSV!',
                    subtitulo: 'Favor baixar o arquivo modelo.',
                    tipo: 'alerta',
                });
            }
        }
    }

    onClear() {
        this.setState({ file: null, nameFile: 'Selecione um arquivo CSV' });
        document.getElementById('customFileLang').value = '';
    }

    onHiden() {
        this.setState({
            modal: false,
            titulo: '',
            subtitulo: '',
            codeError: '',
            tipo: '',
        });
    }

    render() {
        return (
            <div className='col-md-6 mb-4' id='upload-box'>
                {this.state.modal ? (
                    <Modal
                        modal={this.state.modal}
                        metodoHiden={this.onHiden}
                        titulo={this.state.titulo}
                        codeError={this.state.codeError}
                        tipo={this.state.tipo}
                        subtitulo={this.state.subtitulo}
                        validationErrors={this.state.validation_errors}
                    />
                ) : null}

                <div className='card mb-4 download-and-upload' id='upload-box'>
                    <div className='card-header text-center'>
                        Upload (Motor de Incentivos em CSV)
                    </div>

                    <div className='upload-wrapper'>
                        <div className='upload-area'>
                            <div className='input-area'>
                                <div className='custom-file col-md-7'>
                                    <input
                                        type='file'
                                        onChange={this.onChange}
                                        className='custom-file-input'
                                        id='customFileLang'
                                        lang='pt-br'

                                    />
                                    <label className='custom-file-label' htmlFor='customFileLang'>
                                        {this.state.nameFile}
                                    </label>
                                </div>
                            </div>

                            <div className='input-area'>
                                {window.REACT_APP_SITEKEY_RECAPTCHA ? (
                                    <ReCAPTCHA
                                        sitekey={window.REACT_APP_SITEKEY_RECAPTCHA}
                                        ref={this.recaptchaRef}
                                        onChange={this.handleRecaptchaSuccess}
                                        onExpired={this.handleRecaptchaResets}
                                        onErrored={this.handleRecaptchaResets}
                                    />
                                ) : console.error("Couldn't load recaptcha. Please verify!")}
                            </div>

                            <div className='buttons-area'>
                                <button
                                    type='button'
                                    onClick={this.onValidateFormSubmit}
                                    className='btn btn-success '
                                    id='validate-csv'
                                    disabled={!(this.state.validateCaptcha) || this.state.validateLoader}
                                >
                                    {this.state.validateLoader ? <div className="spinner-border spinner-border-sm text-light " role="status"> <span className="sr-only">Loading...</span> </div> : <i className='fas fa-cloud-upload-alt' />}
                                    Validar
                                </button>

                            </div>

                            <div className='buttons-area'>
                                <button
                                    type='button'
                                    onClick={this.onFormSubmit}
                                    className='btn btn-primary '
                                    id='upload-csv'
                                    disabled={!(this.state.validateCaptcha)|| this.state.uploadLoader}
                                >
                                    {this.state.uploadLoader? <div className="spinner-border spinner-border-sm text-light " role="status"> <span className="sr-only">Loading...</span> </div> : <i className='fas fa-cloud-upload-alt' />}
                                    Importar
                                </button>

                            </div>

                          
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ uploadImport: state.table.listImport, tableFilter: state.filter.tableFilter });
const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ getListAllImportsMDI, setTableFilter }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UploadAdminIncentiveEngine);
