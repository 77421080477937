import React, { Component } from 'react';
import { ThemeProvider } from "styled-components";

import GlobalStyle from '../../styles/global';
import theme from '../../styles/theme';

import NavBar from "../../components/navbar/NavBar";
import Footer from "../../components/footer/Footer";
import ListTransactions from "../../components/transactions/list/ListTransactions";
import OptionsAction from "../../components/transactions/optionsAction/OptionsAction";
import OptionsTransactions from "../../components/transactions/optionsAction/OptionsTransactions";
import LoadingTransactions from "../../components/transactions/loading/Loading";
import Filter from "../../components/transactions/filter/Filter";
import Messages from "../../components/transactions/messages/Messages";
import Pagination from "../../components/transactions/pagination/Pagination";
import Modal from "../../components/transactions/cancellation/Modal";
import {
    messageError,
    messageNoTransaction,
    messageDate,
    messageExport,
    messageSuccessExport,
    messageErrorExport,
    messageErrorLimitDaysFilter,
    messageCancellationSuccess,
    messageCancellationError,
    messageCancellationExists
} from "../../components/transactions/messages/ListMessages";
import { scrollTop } from "../../helpers/GlobalFunctions";

import moment from 'moment';
import axios from "axios";

const FileDownload = require('js-file-download');
export default class EletronicExtract extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userAuth: props.state.authenticated && props.state.email && !props.state.modal,

            transactions: [],
            arrayToModal: [],
            renderTransactions: [],
            pageNumbers: [],
            loadingTransactions: false,
            errorFilter: false,
            errorTransactions: false,
            successTransactions: false,
            loadingExport: false,
            visibleModal: false,
            permissionBoxTotalizers: false,
            permissionChargeback: false,
            permissionCancellation: false,
            permissionPage: false,
            permissionPaymentsMarketplace: false,
            isPrevBtnActive: false,
            isNextBtnActive: true,
            loadingMessage: "",
            dateFrom: "",
            dateTo: "",
            currentPage: 1,
            todosPerPage: 15,
            pageBound: 3,
            messageSuccess: messageExport(),
            messageError: messageError(),
            objToCancel: {},
            typeTransactionsActive: {
                id: "vendas",
                name: "Vendas",
                typeSearch: "SALE",
                typeComission: "MARKETPLACE",
                permission: true
            },
            totalizers: {
                totalAmountOfEsferaLiquidationTransactions: "...",
                totalAmountOfSoldTransactions: "...",
                totalAmountOfPartnerLiquidationTransactions: "..."
            },

        };

        this.getTransactions = this.getTransactions.bind(this);
        this.handleChangeFrom = this.handleChangeFrom.bind(this);
        this.handleChangeTo = this.handleChangeTo.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.closeMessageError = this.closeMessageError.bind(this);
        this.downloadCSV = this.downloadCSV.bind(this);
        this.listTransactions = this.listTransactions.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.btnNextClick = this.btnNextClick.bind(this);
        this.btnPrevClick = this.btnPrevClick.bind(this);
        this.setPrevAndNextBtnClass = this.setPrevAndNextBtnClass.bind(this);
        this.updateTransactions = this.updateTransactions.bind(this);
        this.closeMessageSuccess = this.closeMessageSuccess.bind(this);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.confirmCancellation = this.confirmCancellation.bind(this);
        this.updateTransactionType = this.updateTransactionType.bind(this);
        this.updateComissionType = this.updateComissionType.bind(this);
        this.isTransactionsType = this.isTransactionsType.bind(this);
    }

    componentDidMount() {
        if (this.state.userAuth) {
            this.permissions();
            this.getTransactions();
        }
    }

    updateComissionType(type) {
        this.setState({
            typeTransactionsActive: {
                ...this.state.typeTransactionsActive,
                typeComission: type
            }
        }, () => this.getTransactions());
    }

    updateTransactionType(type) {
        this.setState({
            typeTransactionsActive: type,
            dateTo: '',
            dateFrom: ''
        }, () => this.getTransactions());
    }

    valueDateTo(dateTo) {
        var date;

        if (dateTo) {
            date = this.formatDateToTransactions(dateTo);
        } else if (this.state.dateTo && this.isValidDates(this.state.dateTo, this.state.dateFrom)) {
            date = this.formatDateToTransactions(this.state.dateTo);
        } else {
            date = moment().format();
        }

        return date;
    }

    valueDateFrom(dateFrom) {
        var date;

        if (dateFrom) {
            date = this.formatDateToTransactions(dateFrom);
        } else if (this.state.dateFrom && this.isValidDates(this.state.dateTo, this.state.dateFrom)) {
            date = this.formatDateToTransactions(this.state.dateFrom);
        } else {
            date = moment().subtract(7, 'days').format();
        }

        return date;
    }

    getTransactions(dateTo, dateFrom) {
        const TRANSACTIONS_GETNET = `${process.env.TRANSACTIONS_GETNET}`;
        const dateFromValue = this.valueDateFrom(dateFrom);
        const dateToValue = this.valueDateTo(dateTo);
        const searchType = this.state.typeTransactionsActive.typeSearch;
        const comissionType = this.state.typeTransactionsActive.typeComission;

        var params = {
            dateFrom: dateFromValue,
            dateTo: dateToValue,
            searchType: searchType
        }

        if (searchType === 'PAYMENTS' || searchType === 'RECEIVABLES') {
            params = {
                ...params,
                commissionType: comissionType
            }
        }

        this.clearStateTransactions();
        axios
            .get(TRANSACTIONS_GETNET, {
                params: params
            })
            .then((response) => {
                let data = response.data;
                let transactions = this.listTransactions(data.transactions);
                let totalizers = data.totalizers;

                this.setState({
                    transactions: transactions,
                    loadingTransactions: true,
                    totalizers: {
                        totalAmountOfEsferaLiquidationTransactions: this.real(totalizers.totalAmountOfEsferaLiquidationTransactions),
                        totalAmountOfSoldTransactions: this.real(totalizers.totalAmountOfSoldTransactions),
                        totalAmountOfPartnerLiquidationTransactions: this.real(totalizers.totalAmountOfPartnerLiquidationTransactions)
                    }
                })

                if (transactions.length === 0) {
                    this.setState({
                        errorTransactions: true,
                        messageError: messageNoTransaction()
                    })
                }

                this.setPrevAndNextBtnClass();
            })
            .catch((error) => {
                this.setState({
                    errorTransactions: true,
                    loadingTransactions: true,
                    totalizers: {
                        totalAmountOfEsferaLiquidationTransactions: this.real(0),
                        totalAmountOfSoldTransactions: this.real(0),
                        totalAmountOfPartnerLiquidationTransactions: this.real(0)
                    }
                })

                if (error.response) {
                    const httpStatusError = JSON.stringify(error.response.status);
                    if (httpStatusError === "401") {
                        this.props.logout();
                    }
                }
            });

    }

    clearStateTransactions() {
        this.setState({
            loadingTransactions: false,
            errorTransactions: false,
            messageError: messageError(),
            errorFilter: false,
            transactions: [],
            renderTransactions: [],
            pageNumbers: [],
            totalizers: {
                totalAmountOfEsferaLiquidationTransactions: "...",
                totalAmountOfSoldTransactions: "...",
                totalAmountOfPartnerLiquidationTransactions: "..."
            },
            currentPage: 1
        })
    }

    listTransactions(transactions) {
        return transactions.map(transaction => {
            return {
                acquirerTransactionId: transaction.acquirerTransactionId,
                authorizationCode: transaction.authorizationCode,
                confirmationDate: moment(transaction.confirmationDate).format("DD/MM/YYYY"),
                confirmationTime: moment(transaction.confirmationDate).format("HH:mm:ss"),
                detailItensTransactions: this.detailsTransaction(transaction.detailItensTransactions),
                marketplaceTransactionId: transaction.marketplaceTransactionId,
                merchandId: transaction.merchandId,
                numberInstallments: transaction.numberInstallments,
                orderId: transaction.orderId,
                productDescription: transaction.productDescription,
                productId: transaction.productId,
                terminalNsu: transaction.terminalNsu,
                sumDetailsCardPaymentAmount: this.real(transaction.sumDetailsCardPaymentAmount),
                transactionDate: moment(transaction.transactionDate).format("DD/MM/YYYY"),
                transactionTime: moment(transaction.transactionDate).format("HH:mm:ss"),
                transactionStatusCode: transaction.transactionStatusCode,
                transactionStatusDescription: transaction.transactionStatusDescription,
                transactionTypeCode: transaction.transactionTypeCode,
                transactionTypeDescription: transaction.transactionTypeDescription,
                imgBrand: this.formatCardDisplay(transaction.productDescription),
                chargebackAmount: transaction.chargebackAmount || '',
                cancellationAvailable: transaction.transactionSign === "+"
            }
        });
    }

    detailsTransaction(detailItensTransactions) {
        return detailItensTransactions.map(details => {
            return {
                installmentAmount: this.real(details.installmentAmount),
                itemIdProduct: this.splitValue(details.itemId, 0),
                itemIdSku: this.splitValue(details.itemId, 1),
                marketplaceTransactionId: details.marketplaceTransactionId,
                mdrRateAmmount: this.real(details.mdrRateAmmount),
                numberInstallment: details.numberInstallment,
                paymentDate: moment(details.paymentDate).format("DD/MM/YYYY"),
                paymentPlanName: details.paymentPlanName,
                subsellerDescription: details.subsellerDescription,
                subsellerId: details.subsellerId,
                subsellerRateAmount: this.real(details.subsellerRateAmount),
                subsellerRateAmountForCommission: this.real(details.subsellerRateAmountForCommission),
                cancelCustomKey: details.cancelCustomKey,
                cancellationAvailable: details.transactionSign === "+"

            }
        });
    }

    downloadCSV() {
        const TRANSACTIONS_GETNET_DOWNLOAD = `${process.env.TRANSACTIONS_GETNET_DOWNLOAD}`;
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        const searchType = this.state.typeTransactionsActive.typeSearch;
        const comissionType = this.state.typeTransactionsActive.typeComission;
        const isValidDates = this.isValidDates(dateTo, dateFrom);

        const dateFromValue = isValidDates ? this.formatDateToTransactions(dateFrom) : moment().subtract(7, 'days').format();
        const dateToValue = isValidDates ? this.formatDateToTransactions(dateTo) : moment().format();
        const fileNameCSV = this.fileNameCSV();

        this.setState({
            successTransactions: true,
            messageSuccess: messageExport(),
            loadingExport: true,
            errorTransactions: false
        });

        var params = {
            dateFrom: dateFromValue,
            dateTo: dateToValue,
            fileName: fileNameCSV,
            searchType: searchType,
        }

        if (searchType === 'PAYMENTS' || searchType === 'RECEIVABLES') {
            params = {
                ...params,
                commissionType: comissionType
            }
        }

        axios
            ({
                method: 'get',
                url: TRANSACTIONS_GETNET_DOWNLOAD,
                params: params,
                responseType: 'blob'
            })
            .then((response) => {
                FileDownload(response.data, fileNameCSV + '.csv');
                this.setState({
                    successTransactions: true,
                    messageSuccess: messageSuccessExport(),
                    loadingExport: false
                });
            })
            .catch((error) => {
                this.setState({
                    successTransactions: false,
                    loadingExport: false,
                    errorTransactions: true,
                    messageError: messageErrorExport()
                });

                if (error.response) {
                    const httpStatusError = JSON.stringify(error.response.status);
                    if (httpStatusError === "401") {
                        this.props.logout();
                    }
                }
            });
    }

    fileNameCSV() {
        var date = moment().format('DDMMYYYY');
        var hour = moment().format('HHmmss');

        return "transactions_" + date + "_" + hour;
    }

    formatDateToTransactions(date) {
        return moment(date, 'DD/MM/YYYY').format();
    }

    real(value) {
        let valueToFormatReal = value || 0;
        return valueToFormatReal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
    }

    formatCardDisplay(brand) {
        var imgBrand;

        brand = brand.toLowerCase();

        switch (brand) {
            case 'visa':
                imgBrand = "./../icon/visa.png";
                break;
            case 'hipercard':
                imgBrand = "./../icon/hipercard.png";
                break;
            case 'amex':
                imgBrand = "./../icon/amex.png";
                break;
            case 'elo':
                imgBrand = "./../icon/elo.png";
                break;
            case 'diners':
                imgBrand = "./../icon/diners.png";
                break;
            case 'mastercard':
                imgBrand = "./../icon/master.png";
                break;
            default:
                imgBrand = "";
        }

        return imgBrand;
    }

    splitValue(string, index) {
        return (string.split('-', 2))[index];
    }

    applyFilter() {
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;

        if (this.isValidDates(dateTo, dateFrom)) {
            this.getTransactions(dateTo, dateFrom);
        } else if (this.dateLimitDays(dateTo, dateFrom)) {
            this.errorFilter(messageErrorLimitDaysFilter());
        } else {
            this.errorFilter(messageDate());
        }
    }

    isValidDates(dateTo, dateFrom) {
        const formatDateTo = this.formatDateToTransactions(dateTo);
        const formatDateFrom = this.formatDateToTransactions(dateFrom);

        return this.isValidDateLength(dateTo) &&
            this.isValidDateLength(dateFrom) &&
            this.dateIsAfter(formatDateTo, formatDateFrom) &&
            this.dateToBeforeCurrentDateGeneral(formatDateTo) &&
            !this.dateLimitDays(dateTo, dateFrom);
    }

    isValidDateLength(value) {
        return value && value.length === 10;
    }

    dateIsAfter(dateTo, dateFrom) {
        return dateTo >= dateFrom;
    }

    dateToBeforeCurrentDateGeneral(dateTo) {
        let currentDate = moment().format();
        if (this.isTransactionsType("recebiveis")) {
            return true;
        } else {
            return moment(dateTo).isBefore(currentDate);
        }
    }

    dateLimitDays(dateTo, dateFrom) {
        const limit = 30;
        const formatDateTo = moment.utc(this.formatDateToTransactions(dateTo));
        const formatDateFrom = moment.utc(this.formatDateToTransactions(dateFrom));
        const diffInDays = formatDateFrom.diff(formatDateTo, 'days');
        const stringDiffInDays = diffInDays.toString().replace("-", "");

        return Number(stringDiffInDays) > limit;
    }

    errorFilter(message) {
        this.setState({
            errorFilter: true,
            errorTransactions: true,
            messageError: message
        });
    }

    clearFilter() {
        this.setState({
            dateTo: "",
            dateFrom: ""
        }, () => this.getTransactions());

    }

    handleChangeFrom(event) {
        this.setState({ dateFrom: event.target.value });
    }

    handleChangeTo(event) {
        this.setState({ dateTo: event.target.value });
    }

    closeMessageError() {
        this.setState({ errorTransactions: false });
    }

    closeMessageSuccess() {
        this.setState({ successTransactions: false });
    }

    showModal(item, orderId) {
        let detailItensTransactions = item.detailItensTransactions;
        let skus = [];
        let arrayToModal = [];

        if (detailItensTransactions) {
            orderId = item.orderId;

            detailItensTransactions.map((sku) => {
                if (sku.cancellationAvailable) {
                    skus.push(sku.itemIdSku);
                    arrayToModal.push(sku);
                }
            });

        } else {
            if (item.cancellationAvailable) {
                arrayToModal.push(item);
                skus.push(item.itemIdSku);
            }
        }

        this.setState({
            objToCancel: this.objToCancel(skus, orderId) || {},
            arrayToModal: arrayToModal,
            visibleModal: true
        })

    }

    closeModal() {
        this.setState({
            objToCancel: {},
            arrayToModal: [],
            visibleModal: false
        })
    }

    confirmCancellation() {
        var message;
        const TRANSACTIONS_GETNET_CANCEL_ITEMS = `${process.env.TRANSACTIONS_GETNET_CANCEL_ITEMS}`;
        var self = this;
        axios
            ({
                method: 'post',
                url: TRANSACTIONS_GETNET_CANCEL_ITEMS,
                data: self.state.objToCancel
            })
            .then(function (response) {
                scrollTop();
                self.setState({
                    objToCancel: {},
                    arrayToModal: [],
                    visibleModal: false,
                    errorTransactions: false,
                    successTransactions: true,
                    messageSuccess: messageCancellationSuccess()
                });
            })
            .catch(function (error) {
                const httpStatusError = JSON.stringify(error.response.status);

                if (httpStatusError === "401") {
                    this.props.logout();
                } else if (httpStatusError === "403") {
                    message = messageCancellationExists()
                } else {
                    message = messageCancellationError()
                }

                scrollTop();
                self.setState({
                    objToCancel: {},
                    arrayToModal: [],
                    visibleModal: false,
                    errorTransactions: true,
                    successTransactions: false,
                    messageError: message
                });
            });
    }

    objToCancel(skus, orderId) {
        return {
            "user": this.props.state.name,
            "orderId": orderId,
            "skus": skus,
            "reason": "",
            "channel": "Portal Parceiro"
        }
    }

    handleClick(event) {
        let currentPage = Number(event.target.id);
        this.setPrevAndNextBtnClass(currentPage);
    }

    btnPrevClick() {
        let currentPage = this.state.currentPage - 1;
        this.setPrevAndNextBtnClass(currentPage);
    }

    btnNextClick() {
        let currentPage = this.state.currentPage + 1;
        this.setPrevAndNextBtnClass(currentPage);
    }

    setPrevAndNextBtnClass(page) {
        let totalPage = Math.ceil(this.state.transactions.length / this.state.todosPerPage);
        let isNextBtnActive = false;
        let isPrevBtnActive = false;
        let pageCurrent = page || this.state.currentPage;

        if (totalPage === pageCurrent && totalPage > 1) {
            isPrevBtnActive = true;

        } else if (pageCurrent === 1 && totalPage > 1) {
            isNextBtnActive = true;

        } else if (totalPage > 1) {
            isNextBtnActive = true;
            isPrevBtnActive = true;
        }

        this.setState({
            isNextBtnActive: isNextBtnActive,
            isPrevBtnActive: isPrevBtnActive,
            currentPage: pageCurrent
        });

        this.updateTransactions(pageCurrent);
        scrollTop();
    }

    updateTransactions(pageCurrent) {
        const { transactions, todosPerPage } = this.state;
        const pageNumbers = [];
        const indexOfLastTodo = pageCurrent * todosPerPage;
        const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
        const currentTransactions = transactions.slice(indexOfFirstTodo, indexOfLastTodo);
        const totalPages = Math.ceil(transactions.length / todosPerPage).toString();

        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }

        this.setState({
            renderTransactions: currentTransactions,
            pageNumbers: pageNumbers
        });
    }

    isTransactionsType(type) {
        return this.state.typeTransactionsActive.id === type;
    }

    permissions() {
        const admin = this.props.state.isAdmin;
        const partner = this.props.state.isPartner;
        const backoffice = this.props.state.isBackoffice;

        this.setState({
            permissionBoxTotalizers: admin,
            permissionChargeback: admin || backoffice,
            permissionCancellation: admin || backoffice,
            permissionPage: admin || backoffice || partner,
            permissionPaymentsMarketplace: admin || backoffice,
            typeTransactionsActive: {
                ...this.state.typeTransactionsActive,
                typeComission: partner ? "SELLER" : "MARKETPLACE"
            }
        });

    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                {this.state.userAuth && this.state.permissionPage &&
                    <div className="App digital-transactions">

                        <NavBar
                            keycloak={this.props.state.keycloak}
                            logout={this.props.logout}
                            name={this.props.state.name}
                            email={this.props.state.email}
                        />

                        <main>
                            <Filter
                                dateTo={this.state.dateTo}
                                dateFrom={this.state.dateFrom}
                                typeTransactionsActive={this.state.typeTransactionsActive}
                                permissionChargeback={this.state.permissionChargeback}
                                permissionPaymentsMarketplace={this.state.permissionPaymentsMarketplace}
                                filterFrom={this.handleChangeFrom}
                                filterTo={this.handleChangeTo}
                                apply={this.applyFilter}
                                clear={this.clearFilter}
                                updateTransactionType={this.updateTransactionType}
                            />
                            <OptionsTransactions
                                totalizers={this.state.totalizers}
                                quantityTransactions={this.state.transactions.length}
                                loadingTransactions={this.state.loadingTransactions}
                                typeSearch={this.state.typeTransactionsActive.typeSearch}
                                typeComission={this.state.typeTransactionsActive.typeComission}
                                permissionBoxTotalizers={this.state.permissionBoxTotalizers}
                                permissionPaymentsMarketplace={this.state.permissionPaymentsMarketplace}
                                updateComissionType={this.updateComissionType}
                            />
                            <OptionsAction
                                downloadCSV={this.downloadCSV}
                                loadingExport={this.state.loadingExport} />

                            <div className="transactionsmain">
                                <Messages
                                    errorTransactions={this.state.errorTransactions}
                                    messageError={this.state.messageError}
                                    successTransactions={this.state.successTransactions}
                                    messageSuccess={this.state.messageSuccess}
                                    closeMessageError={this.closeMessageError}
                                    closeMessageSuccess={this.closeMessageSuccess}
                                />
                                <ListTransactions
                                    transactions={this.state.renderTransactions}
                                    loadingTransactions={this.state.loadingTransactions}
                                    visibleButtonCancellation={this.isTransactionsType("vendas") && this.state.permissionCancellation}
                                    showModal={this.showModal}
                                />
                                <Pagination
                                    isPrevBtnActive={this.state.isPrevBtnActive}
                                    isNextBtnActive={this.state.isNextBtnActive}
                                    pageNumbers={this.state.pageNumbers}
                                    currentPage={this.state.currentPage}
                                    pageBound={this.state.pageBound}
                                    btnNextClick={this.btnNextClick}
                                    btnPrevClick={this.btnPrevClick}
                                    handleClick={this.handleClick}
                                />

                            </div>
                        </main>
                        <Footer />
                    </div>
                }
                <Modal
                    closeModal={this.closeModal}
                    confirmCancellation={this.confirmCancellation}
                    arrayToModal={this.state.arrayToModal}
                    visibleModal={this.state.visibleModal}
                />
                <LoadingTransactions loadingTransactions={this.state.loadingTransactions} />
                <GlobalStyle />
            </ThemeProvider>


        )
    }
}
